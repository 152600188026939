import playerModel from "./playerModel";
import settingsModel from "./settingsModel";
import alertsModel from "./alertsModel";
import overlayModel from "./overlayModel";

const globalModel = {
  player: playerModel,
  settings: settingsModel,
  alerts: alertsModel,
  overlays: overlayModel,
};

export default globalModel;
