import React from "react";
import styled from "styled-components/macro";
import Title from "../../components/title";
import FeedItem from "./feedItem";

import image1 from "../../assets/images/tmp/tftm.png";
import image2 from "../../assets/images/tmp/officeladies.jpg";

const FeedContainerOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

const FeedContainer = styled.div`
  width: calc(100% - 80px);
  max-width: 768px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderTitle = styled.div`
  font-size: 50px;
  font-family: Circular;
  text-align: center;
  user-select: none;
  color: ${(props) => props.theme.primaryTextColor};
  margin-left: 60px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
`;

const TitleContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const FeedItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const feedData = [
  {
    fullName: "Josh Cawthorne",
    date: "49 mins ago",
    action: "shared",
    sharedObject: "Egg Uber",
    sharedObjectArtist: "Top Flight Time Machine",
    sharedObjectLength: "32 minutes",
    sharedObjectReleaseDate: "Apr 7, 2020",
    textBody:
      "Probably the funniest podcast I've ever heard. Anyone who's not listened to TFTM before is genuinely missing out!",
    objectArtwork: image1,
  },
  {
    fullName: "Josh Cawthorne",
    date: "49 mins ago",
    action: "shared",
    sharedObject: "Egg Uber",
    sharedObjectArtist: "Top Flight Time Machine",
    sharedObjectLength: "32 minutes",
    sharedObjectReleaseDate: "Apr 7, 2020",
    textBody:
      "Probably the funniest podcast I've ever heard. Anyone who's not listened to TFTM before is genuinely missing out!",
    objectArtwork: image1,
  },
  {
    fullName: "Josh Cawthorne",
    date: "49 mins ago",
    action: "shared",
    sharedObject: "Egg Uber",
    sharedObjectArtist: "Top Flight Time Machine",
    sharedObjectLength: "32 minutes",
    sharedObjectReleaseDate: "Apr 7, 2020",
    textBody:
      "Probably the funniest podcast I've ever heard. Anyone who's not listened to TFTM before is genuinely missing out!",
    objectArtwork: image1,
  },
  {
    fullName: "Aaron Walker",
    date: "1 hour ago",
    action: "shared",
    sharedObject: "Wasp bus",
    sharedObjectArtist: "Top Flight Time Machine",
    sharedObjectLength: "33 minutes",
    sharedObjectReleaseDate: "Apr 6, 2020",
    textBody:
      "Important listening for the entire nation. Listen immediately, or perish",
    objectArtwork: image1,
  },
  {
    fullName: "Josh Cawthorne",
    date: "4 hours ago",
    action: "shared",
    sharedObject: "The Injury",
    sharedObjectArtist: "Office Ladies",
    sharedObjectLength: "1 hour, 8 minutes",
    sharedObjectReleaseDate: "Mar 4, 2020",
    textBody:
      "Just discovered that Angela and Pam from The Office are doing a podcast where they watch through The Office one episode at a time and talk about the behind the scenes of making it etc. Really good listen if you're a fan",
    objectArtwork: image2,
  },
];

function Feed(props) {
  return (
    <FeedContainerOuter>
      <FeedContainer>
        <TitleContainer>
          <HeaderTitle>Feed Alpha</HeaderTitle>
        </TitleContainer>
        <FeedItems>
          {feedData.map((data, index) => (
            <FeedItem data={data} key={index} />
          ))}
        </FeedItems>
      </FeedContainer>
    </FeedContainerOuter>
  );
}

export default Feed;
