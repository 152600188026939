import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { keyframes } from "styled-components/macro";

const animate = keyframes`
    0% {
        clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 6% 100%);
    }
`;

const MenuItemContainer = styled(Link)`
  text-decoration: none;
  font-family: circular;
  font-weight: 500;
  font-size: 22px;
  user-select: none;
  color: ${(props) => props.theme.ctaTextColor};
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: 300ms;
  text-transform: lowercase;
  clip-path: polygon(3.5% 1%, 100% 0%, 97.5% 100%, 0% 100%);
  animation: ${animate} 500ms alternate linear infinite;
  margin-top: 3px;
  :hover {
    background-color: #3a62f7;
    transition: 300ms;
    color: #fff;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
`;

const MenuItem = styled.div``;

function menuItem(props) {
  return <MenuItemContainer to={props.link}>{props.text}</MenuItemContainer>;
}

export default menuItem;
