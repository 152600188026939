import React from "react";
import styled from "styled-components/macro";
import { useStoreState } from "easy-peasy";

const TitleContainer = styled.div`
  font-size: ${(props) => props.fontSize};
  font-family: prophet;
  font-weight: 800;
  text-align: center;
  user-select: none;
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  span {
    color: #fff;
    background-color: #3a62f7;
    padding-left: 25px;
    padding-right: 25px;
    clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
    transition: 500ms;
    :hover {
      clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 6% 100%);
      transition: 500ms;
    }
  }

  @media (max-width: 700px) {
    font-size: ${(props) => props.mobileFontSize};
    line-height: ${(props) => props.mobileLineHeight};
  }
`;

function Title(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);
  return (
    <TitleContainer
      color={props.color || themeSetting === "dark" ? "#fff" : "#000"}
      padding={props.padding || 0}
      fontSize={props.fontSize || "32px"}
      mobileFontSize={props.mobileFontSize || "22px"}
      lineHeight={props.lineHeight || "32px"}
      mobileLineHeight={props.mobileLineHeight || "22px"}
    >
      {props.text}
    </TitleContainer>
  );
}

export default Title;
