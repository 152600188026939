import React from "react";
import styled from "styled-components/macro";

const ComingSoonContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  font-family: circular;
  font-weight: bold;
  font-size: 22px;
  color: #54575a;
  user-select: none;
  transition: 300ms;
  :hover {
    span {
      transition: 300ms;
      color: #3a62f7;
    }
  }
`;

function ComingSoon() {
  return (
    <ComingSoonContainer>
      Coming&nbsp;<span> 2020</span>.
    </ComingSoonContainer>
  );
}

export default ComingSoon;
