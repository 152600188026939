import { createStore } from "easy-peasy";
import model from "./model/index";

const store = createStore(model);

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./model", () => {
      store.reconfigure(model);
    });
  }
}

export default store;
