import React from "react";
import styled from "styled-components/macro";

import SidebarItem from "./sidebarItem";

const SettingsSidebarContainer = styled.div`
  height: 100%;
  width: 475px;
  padding-top: 60px;
  background-color: ${(props) => props.theme.accentedPrimary};
  transition: 200ms;
  overflow-y: auto;

  @media (max-width: 1024px) {
    transition: 200ms;
    width: 75vw;
    left: ${(props) => props.mobileWidth};
    position: absolute;
    top: 0;
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 22px;
  color: ${(props) => props.theme.opevoBrandAccent};
  user-select: none;
`;

function SettingsSidebar(props) {
  return (
    <SettingsSidebarContainer
      mobileWidth={props.mobileMenuActive ? "0" : "-75vw"}
    >
      <CategoryTitle>app settings</CategoryTitle>
      <SidebarItem
        text={"apperance"}
        id={"apperance"}
        onClickFunction={props.handlePageChange}
        active={props.page === "apperance" ? true : false}
      />
      <SidebarItem
        text={"playback"}
        id={"playback"}
        onClickFunction={props.handlePageChange}
        active={props.page === "playback" ? true : false}
      />
      <SidebarItem
        text={"social"}
        id={"social"}
        onClickFunction={props.handlePageChange}
        active={props.page === "social" ? true : false}
      />
      <SidebarItem
        text={"other"}
        id={"other"}
        onClickFunction={props.handlePageChange}
        active={props.page === "other" ? true : false}
      />
      <CategoryTitle>user settings</CategoryTitle>
      <SidebarItem
        text={"my account"}
        id={"account"}
        onClickFunction={props.handlePageChange}
        active={props.page === "account" ? true : false}
      />
      <SidebarItem
        text={"privacy & safety"}
        id={"privacy"}
        onClickFunction={props.handlePageChange}
        active={props.page === "privacy" ? true : false}
      />
      <SidebarItem
        text={"log out"}
        id={"logout"}
        link={"/"}
        onClickFunction={props.handlePageChange}
        active={props.page === "logout" ? true : false}
      />
    </SettingsSidebarContainer>
  );
}

export default SettingsSidebar;
