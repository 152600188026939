import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { useStoreState } from "easy-peasy";

const ActiveItemAnim = keyframes`
    0%{
        margin-left: 0px;
    }
    100% {
        margin-left: 20px;
    }
`;

const InActiveAnim = keyframes`
    0%{
        margin-left: 20px;
    }
    100% {
        margin-left: 0px;
    }
`;

const ActiveSidebarItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  animation: ${ActiveItemAnim} 300ms ease-out;
  margin-left: 20px;
  margin: ${(props) => props.margin};
`;

const SidebarItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  animation: ${InActiveAnim} 300ms ease-out;
  margin: ${(props) => props.margin};
`;

const ActiveTextContainer = styled.div`
  color: #fff;
  background-color: ${(props) => props.theme.opevoBrandAccent};
  transition: 300ms;
  font-size: 22px;
  display: flex;
  border-radius: 10px;
  height: 27px;
  padding: 8px;
  padding-left: 15px;
  user-select: none;
  padding-right: 15px;
  :hover {
    transition: 300ms;
    color: ${(props) => props.hoverColor};
  }
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: 22px;
  transition: 300ms;
  height: 27px;
  border-radius: 10px;
  user-select: none;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 10px;
  cur :hover {
    background-color: ${(props) => props.color};
    transition: 300ms;
    color: ${(props) => props.theme.primary};
  }
`;

const IconContainer = styled.div`
  height: 27px;
  width: 27px;
  margin-right: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 29px;
  line-height: 0px;
  display: -webkit-box;
  border-radius: 5px;
  background-color: ${(props) => props.theme.labelBackgroundColor};
  color: ${(props) => props.theme.labelText};
  font-weight: 800;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 25px;
`;

const LabelIconContainer = styled.div`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;

function SidebarItem(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);

  return (
    <React.Fragment>
      {props.active ? (
        <ActiveSidebarItemContainer
          backgroundColor={themeSetting === "dark" ? "#eee" : "#3e3d44"}
          margin={props.margin}
        >
          <ActiveTextContainer
            color={themeSetting === "dark" ? "#3a3856" : "#cecede"}
            hoverColor={themeSetting === "dark" ? "#aaaac9" : "#d3d3ea"}
          >
            <IconContainer>
              <props.Icon fill={themeSetting === "dark" ? "#fff" : "#fff"} />
            </IconContainer>
            {props.text}
          </ActiveTextContainer>
          {props.label && (
            <LabelContainer>
              <LabelIconContainer>
                <props.LabelIcon
                  fill={themeSetting === "dark" ? "#dbde45" : "#000"}
                />
              </LabelIconContainer>
              {props.label}
            </LabelContainer>
          )}
        </ActiveSidebarItemContainer>
      ) : (
        <SidebarItemContainer
          onClick={() => props.updatePage(props.id)}
          margin={props.margin}
        >
          <TextContainer
            color={themeSetting === "dark" ? "#aaaac9" : "#7b7b98"}
            hoverColor={themeSetting === "dark" ? "#aaaac9" : "#d3d3ea"}
            backgroundColor={props.backgroundColor}
          >
            <IconContainer>
              <props.Icon fill={themeSetting === "dark" ? "#fff" : "#000"} />
            </IconContainer>
            {props.text}
          </TextContainer>
          {props.label && (
            <LabelContainer>
              <LabelIconContainer>
                <props.LabelIcon
                  fill={themeSetting === "dark" ? "#dbde45" : "#d03131"}
                />
              </LabelIconContainer>
              {props.label}
            </LabelContainer>
          )}
        </SidebarItemContainer>
      )}
    </React.Fragment>
  );
}

export default SidebarItem;
