import React from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { mobileModel } from "react-device-detect";

import { ReactComponent as DashboardIcon } from "../../assets/images/icons/dashboard.svg";
import { ReactComponent as TrendingIcon } from "../../assets/images/icons/trendingUp.svg";
import { ReactComponent as InboxIcon } from "../../assets/images/icons/messages.svg";
import { ReactComponent as SubscriptionsIcon } from "../../assets/images/icons/subscriptions.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/icons/playCircle.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/icons/menu.svg";

import ToolbarItem from "./mobileToolbarItem";

const MobileToolbarContainer = styled.div`
  width: 100%;
  height: 90px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.accentedPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  backdrop-filter: blur(0px);
  padding-bottom: ${(props) => props.extraPadding};
`;

const ToolbarItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 45px;
  margin-left: 45px;
`;

function MobileToolbar(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);
  const toggleSettingsPanel = useStoreActions(
    (actions) => actions.overlays.updateSettingsOverlay
  );
  let history = useHistory();
  let location = useLocation();

  function handlePageUpdate(id) {
    if (id === "settings") {
      toggleSettingsPanel(true);
    } else {
      history.push("/" + id);
    }
  }

  return (
    <MobileToolbarContainer
      backgroundColor={themeSetting === "dark" ? "#575757" : "#fafafa"}
      extraPadding={mobileModel === "iPhone" ? "0px" : "0px"}
    >
      <ToolbarItemContainer>
        <ToolbarItem
          updatePage={handlePageUpdate}
          Icon={DashboardIcon}
          id={"feed"}
          active={location.pathname === "/feed" ? true : false}
        />
        <ToolbarItem
          updatePage={handlePageUpdate}
          Icon={PlayIcon}
          id={"player"}
          active={location.pathname === "/player" ? true : false}
        />
        <ToolbarItem
          updatePage={handlePageUpdate}
          Icon={InboxIcon}
          id={"inbox"}
          active={location.pathname === "/inbox" ? true : false}
        />
        <ToolbarItem
          updatePage={handlePageUpdate}
          Icon={SubscriptionsIcon}
          id={"Subscriptions"}
          active={location.pathname === "/Subscriptions" ? true : false}
        />
        <ToolbarItem
          updatePage={handlePageUpdate}
          Icon={MenuIcon}
          id={"settings"}
          active={location.pathname === "/settings" ? true : false}
        />
      </ToolbarItemContainer>
    </MobileToolbarContainer>
  );
}

export default MobileToolbar;
