import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import RegisterForm from "./registerForm";
import Spacer from "../../utils/spacer";
import CtaItem from "../../components/ctaItem";

import GoogleLogo from "../../assets/images/google-logo.png";
import AppleLogo from "../../assets/images/apple-logo.png";
import FacebookLogo from "../../assets/images/facebook-logo.png";

const RegisterBoxContainer = styled.div`
  width: 450px;
  background-color: ${(props) => props.theme.accentedPrimary};
  color: ${(props) => props.theme.primaryTextColor};
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-top: 275px;
    height: 100vh;
    width: 100vw;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-family: circular;
  font-weight: 500;
  text-align: center;
  user-select: none;
  margin-bottom: 5px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-family: circular;
  font-weight: 500;
  text-align: center;
  user-select: none;
  color: ${(props) => props.theme.subTextColor};
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
`;

function RegisterBox() {
  return (
    <RegisterBoxContainer>
      <Title>hello there</Title>
      <Subtitle>register to get listening</Subtitle>
      <Spacer height={"15px"} />
      <RegisterForm />
      <Spacer height={"30px"} />
      <Subtitle>or, register with</Subtitle>
      <Spacer height={"15px"} />
      <LogosContainer>
        <LogoImg src={GoogleLogo} />
        <LogoImg src={FacebookLogo} />
        <LogoImg src={AppleLogo} style={{ marginTop: "-3.5px" }} />
      </LogosContainer>
      <Spacer height={"35px"} />
      <Subtitle>have we already met?</Subtitle>
      <Spacer height={"5px"} />
      <CtaItem
        link={"/login"}
        text={"login"}
        textColor={"#656363"}
        hoverBackgroundColor={"#3a62f7"}
        animated
      />
    </RegisterBoxContainer>
  );
}

export default RegisterBox;
