import { action } from "easy-peasy";

const overlayModel = {
  settingsOverlay: false,
  updateSettingsOverlay: action((state, payload) => {
    state.settingsOverlay = payload;
  }),
};

export default overlayModel;
