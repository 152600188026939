import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import LoginForm from "./loginForm";
import Spacer from "../../utils/spacer";
import CtaItem from "../../components/ctaItem";

import GoogleLogo from "../../assets/images/google-logo.png";
import AppleLogo from "../../assets/images/apple-logo.png";
import AppleLogoWhite from "../../assets/images/apple-logo-white.png";
import FacebookLogo from "../../assets/images/facebook-logo.png";

const LoginBoxContainer = styled.div`
  width: 450px;
  background-color: ${(props) => props.theme.accentedPrimary};
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    height: 100vh;
    width: 100vw;
    padding: 0;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-family: circular;
  font-weight: 500;
  text-align: center;
  user-select: none;
  margin-bottom: 5px;
  color: ${(props) => props.theme.primaryTextColor};
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-family: circular;
  font-weight: 500;
  text-align: center;
  user-select: none;
  color: ${(props) => props.theme.subTextColor};
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
`;

function LoginBox(props) {
  return (
    <LoginBoxContainer>
      <Title>hello, again</Title>
      <Subtitle>login to get listening</Subtitle>
      <Spacer height={"15px"} />
      <LoginForm />
      <Spacer height={"30px"} />
      <Subtitle>or, login with</Subtitle>
      <Spacer height={"15px"} />
      <LogosContainer>
        <LogoImg src={GoogleLogo} />
        <LogoImg src={FacebookLogo} />
        <LogoImg
          src={props.darkMode ? AppleLogoWhite : AppleLogo}
          style={{ marginTop: "-3.5px" }}
        />
      </LogosContainer>
      <Spacer height={"35px"} />
      <Subtitle>haven't we met?</Subtitle>
      <Spacer height={"5px"} />
      <CtaItem
        link={"/register"}
        text={"register"}
        textColor={"#656363"}
        hoverBackgroundColor={"#3a62f7"}
        animated
      />
    </LoginBoxContainer>
  );
}

export default LoginBox;
