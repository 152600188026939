import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components/macro";
import { Helmet } from "react-helmet";

import useSystemTheme from "react-use-system-theme";
import { theme } from "./utils/themes";
import { useStoreActions, useStoreState } from "easy-peasy";
import { AudioPlayerProvider } from "react-use-audio-player";

import SidebarController from "./core/navigation/sidebarController";
import Feed from "./core/feed";
import Search from "./core/search";
import Trending from "./core/trending";
import Inbox from "./core/inbox";
import Subscriptions from "./core/subscriptions";
import Following from "./core/following";
import Login from "./pages/login";
import Register from "./pages/register";
import Home from "./pages/home";
import NoMatch from "./pages/noMatch";
import Settings from "./core/settings/settings";
import Feedback from "./core/feedback/feedback";
import TempItems from "./core/player/tempItems";
import Player from "./core/player";
import Shows from "./core/shows";
import Show from "./core/show";

require("dotenv").config();

const AppContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.primaryTextColor};
  overflow: hidden;
`;

const AppInnerContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-bottom: ${(props) => props.paddingBottom};
  @media (max-width: 1024px) {
    display: block;
    flex-direction: unset;
  }
`;

function App() {
  const [page, setPage] = useState("trending");
  const [settingsPage, setSettingsPage] = useState(false);
  const [feedbackPage, setFeedbackPage] = useState(false);
  const [previousPage, setPreviousPage] = useState("dashboard");
  const updateSidebar = useStoreActions(
    (actions) => actions.settings.updateSidebar
  );

  const settingsPageActive = useStoreState(
    (state) => state.overlays.settingsOverlay
  );

  const themeSetting = useStoreState((store) => store.settings.theme);
  const [themeState, setthemeState] = useState(themeSetting);
  const systemTheme = useSystemTheme();
  const forcedTheme = useStoreState((store) => store.settings.forcedTheme);
  const updateTheme = useStoreActions(
    (actions) => actions.settings.updateTheme
  );
  const updateForcedTheme = useStoreActions(
    (actions) => actions.settings.updateForcedTheme
  );

  useEffect(() => {
    let sidebarSetting = localStorage.getItem("sidebarSetting");
    updateSidebar(sidebarSetting);
    if (forcedTheme === null) {
      updateForcedTheme(false);
      localStorage.setItem("themeSetting", themeState);
      localStorage.setItem("forcedThemeSetting", false);
    }
    if (!forcedTheme || forcedTheme === "false") {
      setthemeState(systemTheme);
      updateTheme(themeState);
      localStorage.setItem("themeSetting", themeState);
    }
  });

  function updatePage(updatedPage) {
    if (updatedPage === "settings") {
      setSettingsPage(true);
    } else if (updatedPage === "feedback") {
      setFeedbackPage(true);
    } else {
      setPreviousPage(page);
      setPage(updatedPage);
    }
  }

  function closeSettings() {
    setSettingsPage(false);
  }

  function closeFeedback() {
    setFeedbackPage(false);
  }

  return (
    <AudioPlayerProvider>
      <ThemeProvider
        theme={
          forcedTheme
            ? themeSetting === "dark"
              ? theme["dark"]
              : theme["light"]
            : theme[systemTheme || "dark"]
        }
      >
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
        </Helmet>
        <Router>
          <AppContainer>
            <AppInnerContainer>
              {settingsPageActive ? <Settings /> : null}
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/register" exact>
                  <Register />
                </Route>
                <Route path="/feed" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Feed />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/following" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Following />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/player" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <TempItems />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/search" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Search />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/trending" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Trending />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/inbox" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Inbox />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/subscriptions" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Subscriptions />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/shows" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Shows />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route path="/show/:id" exact>
                  <ComponentContainer>
                    <SidebarController />
                    <Show />
                    <Player />
                  </ComponentContainer>
                </Route>
                <Route exact>
                  <NoMatch />
                </Route>
              </Switch>
            </AppInnerContainer>
          </AppContainer>
        </Router>
      </ThemeProvider>
    </AudioPlayerProvider>
  );
}

export default App;
