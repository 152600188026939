import React from "react";
import styled from "styled-components/macro";

const SpacerContent = styled.div`
  width: 100%;
  height: ${(props) => props.height};

  @media (max-width: 1024px) {
    height: ${(props) => props.tabletHeight};
  }

  @media (max-width: 700px) {
    height: ${(props) => props.mobileHeight};
  }
`;

function Spacer(props) {
  return (
    <SpacerContent
      height={props.height}
      tabletHeight={props.tabletHeight || props.height}
      mobileHeight={props.mobileHeight || props.height}
    />
  );
}

export default Spacer;
