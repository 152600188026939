import React from "react";
import styled from "styled-components/macro";

const PageComingSoonContainer = styled.div``;

const Title = styled.div`
  font-size: 40px;
  font-family: circular;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Subtext = styled.div`
  font-size: 18px;
  font-family: circular;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${(props) => props.theme.subTextColor};
`;

function PageComingSoon(props) {
  return (
    <PageComingSoonContainer>
      <Title>{props.title}</Title>
      <Subtext>This page is coming soon.</Subtext>
    </PageComingSoonContainer>
  );
}

export default PageComingSoon;
