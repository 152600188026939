import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components/macro";
import Title from "../../components/title";

import { ReactComponent as CloseIcon } from "../../assets/images/icons/cancel.svg";

const openAnim = keyframes` 
    0% {transform: scale(1.2);}
    100% {transform: scale(1);}
`;

const closeAnim = keyframes` 
    0% {transform: scale(1);}
    100% {transform: scale(1.2); opacity: 0;}
`;

const FeedbackContainerOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  overflow: hidden;
  width: 100%;
  height: 100%;
  animation-name: ${(props) => props.anim};
  animation-duration: 200ms;
  animation-iteration-count: 1;
`;

const FeedbackContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 0s;
`;

const FeedbackPage = styled.div`
  padding: 40px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.accentedPrimaryHover};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 100px;

    max-width: 100%;
    margin-left: 25px;

    margin-right: 45px;
  }
`;

const Subtext = styled.div`
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: -10px;
  font-weight: 500;
  color: ${(props) => props.theme.subTextColor};
`;

const CloseIconContainer = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 10px;
`;

const CloseButton = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: ${(props) => props.theme.accentedPrimary};
  }
`;

function Feedback(props) {
  const [closingPanel, setClosingPanel] = useState(false);

  function handleClose(props) {
    setClosingPanel(true);
    setTimeout(() => {
      props();
    }, 200);
  }
  return (
    <FeedbackContainerOuter anim={closingPanel ? closeAnim : openAnim}>
      <FeedbackContainer>
        <FeedbackPage>
          <Title
            text={"Feedback? Already?!"}
            color={props.darkMode ? "#fff" : "#000"}
          />
          <Subtext>
            I like your enthusiasm, but let's just calm it down a bit, OK?
          </Subtext>
          <Subtext>Come back another time to feed-back.</Subtext>
          <CloseButton onClick={() => handleClose(props.closeFeedback)}>
            <CloseIconContainer>
              <CloseIcon fill={props.darkMode ? "#fff" : "#000"} />
            </CloseIconContainer>
            Close
          </CloseButton>
        </FeedbackPage>
      </FeedbackContainer>
    </FeedbackContainerOuter>
  );
}

export default Feedback;
