export const theme = {
  dark: {
    opevoBrandAccent: "#3a62f7",
    opevoBrandAccentHover: "#b9c6f7",
    primary: "#141319",
    primaryInverted: "#fff",
    accentedPrimary: "#26252b",
    accentedPrimaryHover: "#3a3c42",
    primaryTextColor: "#fff",
    subTextColor: "#c5c5c5",
    subTextColorClearer: "#a2a2a2",
    inputBackgroundColor: "#303238",
    inputBackgroundColorActive: "#303238",
    ctaTextColor: "#eee",
    labelBackgroundColor: "#44414c",
    labelText: "#dbde45",
  },
  light: {
    opevoBrandAccent: "#3a62f7",
    opevoBrandAccentHover: "#8fa6f9",
    primary: "#fff",
    primaryInverted: "#141319",
    accentedPrimary: "#f4f4f4",
    accentedPrimaryHover: "#e8e8e8",
    primaryTextColor: "#000",
    subTextColor: "#656363",
    subTextColorClearer: "#3c3c3c",
    inputBackgroundColor: "#fffcfc",
    ctaTextColor: "#1d1e1f",
    inputBackgroundColor: "#e4e4e4",
    inputBackgroundColorActive: "#e4e4e4",
    labelBackgroundColor: "#c2c3c3",
    labelText: "#000000",
  },
};
