import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { shows } from "./tempData";
import Title from "../../components/title";

const api = process.env.REACT_APP_API;

const ShowsContainer = styled.div`
  margin: 20px;
`;

const ShowItem = styled.div`
  height: 100px;
  background-color: ${(props) => props.theme.accentedPrimary};
  padding: 20px;
  color: ${(props) => props.theme.primaryTextColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 150px;
  font-family: Circular;
`;

function Shows() {
  let history = useHistory();
  function handleItemSelect(id) {
    history.push("/show/" + id);
  }

  useEffect(() => {
    axios
      .get(api + "/shows")
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  return (
    <ShowsContainer>
      <StyledTitle
        text={"This is a temporary UI, incase you didn't guess."}
        padding={"0px 0px 50px 0px"}
      />
      {shows.map((item, index) => (
        <ShowItem onClick={() => handleItemSelect(item.id)}>
          {item.title}
        </ShowItem>
      ))}
    </ShowsContainer>
  );
}

export default Shows;
