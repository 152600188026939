import { action } from "easy-peasy";

const settingsModel = {
  sidebar: localStorage.getItem("sidebarSetting"),
  updateSidebar: action((state, payload) => {
    state.sidebar = payload;
  }),
  theme: localStorage.getItem("themeSetting"),
  updateTheme: action((state, payload) => {
    state.theme = payload;
  }),
  forcedTheme: localStorage.getItem("forcedThemeSetting"),
  updateForcedTheme: action((state, payload) => {
    state.forcedTheme = payload;
  }),
  hidePlaybar: localStorage.getItem("hidePlaybar"),
  updateHidePlaybar: action((state, payload) => {
    state.hidePlaybar = payload;
  }),
};

export default settingsModel;
