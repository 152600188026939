import { action } from "easy-peasy";

const playerModel = {
  playing: false,
  updatePlaying: action((state, payload) => {
    state.playing = payload;
  }),
  volume: 1,
  updateVolume: action((state, payload) => {
    state.volume = payload;
  }),
  url: "null",
  updateUrl: action((state, payload) => {
    state.url = payload;
  }),
  title: null,
  updateTitle: action((state, payload) => {
    state.title = payload;
  }),
};

export default playerModel;
