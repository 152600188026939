import React, { useState } from "react";
import styled from "styled-components/macro";
import { playbackItemsArray } from "./playbackItems";
import { useStoreState, useStoreActions } from "easy-peasy";

const PlaybackItems = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  user-select: none;
  margin-top: 50px;
  margin-left: 50px;
`;

const PlaybackItem = styled.div`
  height: 100px;
  width: 100px;
  background-color: ${(props) => props.theme.inputBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  user-select: none;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TitleText = styled.div`
  font-size: 22px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: circular;
  color: ${(props) => props.theme.primaryTextColor};
  margin-bottom: 50px;
`;

function TempItems() {
  const updateUrl = useStoreActions((actions) => actions.player.updateUrl);
  const updateTitle = useStoreActions((actions) => actions.player.updateTitle);
  const [cooldown, setCooldown] = useState(false);

  function handleUpdateUrl(item) {
    if (!cooldown) {
      setCooldown(true);
      updateUrl(item.url);
      updateTitle(item.title);
      setTimeout(() => {
        setCooldown(false);
      }, 1750);
    }
  }
  return (
    <PlaybackItems>
      <TitleText>This is a temporary UI to select some demo songs.</TitleText>
      <ItemsContainer>
        {playbackItemsArray.map((item, index) => (
          <PlaybackItem onClick={() => handleUpdateUrl(item)}>
            {item.title}
          </PlaybackItem>
        ))}
      </ItemsContainer>
    </PlaybackItems>
  );
}

export default TempItems;
