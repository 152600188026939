import { action } from "easy-peasy";

const alertsModel = {
  playerBeta: false,
  updatePlayerBeta: action((state, payload) => {
    state.playerBeta = payload;
  }),
};

export default alertsModel;
