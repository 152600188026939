import React from "react";
import styled from "styled-components/macro";

import LoggedOutHeader from "../core/header/loggedOutHeader";
import AnimatedTitle from "../components/animatedTitle";
import Title from "../components/title";
import CtaItem from "../components/ctaItem";

const NoMatchContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function NoMatch() {
  return (
    <NoMatchContainer>
      <LoggedOutHeader />
      <Title
        text={"UH-OH"}
        color={"#3a62f7"}
        lineHeight={"40px"}
        mobileLineHeight={"25px"}
        mobileHeight={"20px"}
        fontSize={"40px"}
      />
      <Title
        text={"It's a Four Oh Four"}
        color={"#000"}
        lineHeight={"80px"}
        mobileLineHeight={"75px"}
        mobileHeight={"60px"}
        fontSize={"80px"}
      />
      <CtaItem
        text={"get outta here. and don't come back."}
        link={"/"}
        textColor={"#656363"}
        fontSize={"40px"}
        paddingLeft={"40px"}
        paddingRight={"40px"}
        hoverBackgroundColor={"#3a62f7"}
        animated
      />
    </NoMatchContainer>
  );
}

export default NoMatch;
