import React from "react";
import styled from "styled-components/macro";
import { mobileModel } from "react-device-detect";
import { useStoreState } from "easy-peasy";
import { useAudioPlayer } from "react-use-audio-player";
import opevoLogo from "../../assets/images/opevoLogo.png";

const PlayerContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: calc(100vw - ${(props) => props.sidebarWidth});
  height: 90px;
  margin-left: ${(props) => props.sidebarWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.accentedPrimary};
  color: ${(props) => props.theme.primaryTextColor};
  @media (max-width: 1024px) {
    width: 100vw;
    margin-left: 0px;
    background-color: ${(props) => props.theme.accentedPrimaryHover};
    margin-bottom: calc(90px + ${(props) => props.extraPadding});
    height: 90px;
  }
`;

const PlayingItem = styled.div`
  margin-left: 10px;
  font-family: circular;
  font-weight: 800;
  color: ${(props) => props.theme.primaryTextColor};
  span {
    font-weight: 100;
  }
`;

const DrawerContainer = styled.div`
  width: 80vw;
  height: 100%;
  background-color: #000;
`;

function Player() {
  const sidebarState = useStoreState((state) => state.settings.sidebar);
  const playerUrl = useStoreState((state) => state.player.url);
  const title = useStoreState((state) => state.player.title);
  const hidePlaybar = useStoreState((state) => state.settings.hidePlaybar);

  const {
    togglePlayPause,
    ready,
    loading,
    playing,
    play,
    pause,
  } = useAudioPlayer({
    src: playerUrl,
    format: "mp3",
    autoplay: true,
  });

  if (playing) {
    if ("mediaSession" in navigator) {
      // eslint-disable-next-line
      navigator.mediaSession.metadata = new MediaMetadata({
        title: title,
        artist: "Opevo",
        album: "Opevo",
        artwork: [{ src: opevoLogo, sizes: "512x512", type: "image/png" }],
      });
    }
  }

  function PlayerObjController() {
    if (playing) {
      return <PlayerObj />;
    } else if (hidePlaybar === "true") {
      return <PlayerObj />;
    } else {
      return null;
    }
  }

  function PlayerObj() {
    return (
      <PlayerContainer
        extraPadding={mobileModel === "iPhone" ? "15px" : "0px"}
        sidebarWidth={sidebarState === "compact" ? "95px" : "325px"}
      >
        {loading ? "Loading... " : null}
        {ready ? (
          <button onClick={togglePlayPause}>
            {playing ? "Pause" : "Play"}
          </button>
        ) : null}

        <PlayingItem>
          <span>Now Playing:</span> {title}
        </PlayingItem>
      </PlayerContainer>
    );
  }

  return <PlayerObjController />;
}

export default Player;
