export const shows = [
  {
    id: "top_flight_time_machine",
    title: "Top Flight Time Machine",
    author: "Andy Dawson and Sam Delaney",
    genres: ["Comedy", "Sports"],
    artwork: "#",
    url: "#",
  },
  {
    id: "office_ladies",
    title: "Office Ladies",
    author: "Jenna Fischer and Angela Kinsey",
    genres: ["Rewatch"],
    artwork: "#",
    url: "#",
  },
];
