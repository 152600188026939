import React from "react";
import styled from "styled-components/macro";
import TickboxToggle from "./tickboxToggle";
import { useStoreState, useStoreActions, useStore } from "easy-peasy";

const SettingsPageContainer = styled.div`
  padding-bottom: 60px;
`;

const Title = styled.div`
  font-size: 40px;
  font-family: circular;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SettingsItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SettingTitle = styled.div`
  font-size: 18px;
  font-family: circular;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${(props) => props.theme.subTextColor};
`;

const SettingsRadioButton = styled.input`
  margin-right: 10px;
  &[type="radio"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    -ms-appearance: checkbox;
  }
`;

function Apperance(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);
  const updateThemeSetting = useStoreActions(
    (actions) => actions.settings.updateTheme
  );
  const forcedTheme = useStoreState((store) => store.settings.forcedTheme);
  const updateForcedTheme = useStoreActions(
    (actions) => actions.settings.updateForcedTheme
  );
  const sidebarSetting = useStoreState((state) => state.settings.sidebar);
  const updateSidebarSetting = useStoreActions(
    (actions) => actions.settings.updateSidebar
  );
  const hidePlaybar = useStoreState((state) => state.settings.hidePlaybar);
  const updateHidePlaybar = useStoreActions(
    (actions) => actions.settings.updateHidePlaybar
  );

  function setSidebarSettingExpanded() {
    localStorage.setItem("sidebarSetting", "expanded");
    updateSidebarSetting("expanded");
  }

  function setSidebarSettingCompact() {
    localStorage.setItem("sidebarSetting", "compact");
    updateSidebarSetting("compact");
  }

  function enableDarkMode() {
    updateForcedTheme(true);
    updateThemeSetting("dark");
    localStorage.setItem("themeSetting", "dark");
    localStorage.setItem("forcedThemeSetting", "true");
  }

  function enableLightMode() {
    updateForcedTheme(true);
    updateThemeSetting("light");
    localStorage.setItem("themeSetting", "light");
    localStorage.setItem("forcedThemeSetting", "true");
  }

  function enableAutoTheme() {
    updateForcedTheme(false);
    localStorage.setItem("forcedThemeSetting", "false");
  }

  function setPlaybarSettingsTrue() {
    localStorage.setItem("hidePlaybar", "true");
    updateHidePlaybar("true");
  }

  function setPlaybarSettingsFalse() {
    updateHidePlaybar("false");
    localStorage.setItem("hidePlaybar", "false");
  }

  return (
    <SettingsPageContainer>
      <Title>apperance</Title>

      <SettingsItem>
        <SettingTitle>theme</SettingTitle>
        <TickboxToggle
          clickFunction={enableAutoTheme}
          themeSetting={themeSetting}
          active={
            forcedTheme === false || forcedTheme === "false" ? true : false
          }
          label={"auto"}
          subtext={"automatically updates based on your os theme, like magic"}
        />
        <TickboxToggle
          clickFunction={enableDarkMode}
          themeSetting={themeSetting}
          active={
            forcedTheme === false || forcedTheme === "false"
              ? false
              : themeSetting === "dark"
              ? true
              : false
          }
          label={"dark"}
          subtext={"for when it's not just a phase, mom"}
        />
        <TickboxToggle
          clickFunction={enableLightMode}
          themeSetting={themeSetting}
          active={
            forcedTheme === false || forcedTheme === "false"
              ? false
              : themeSetting === "light"
              ? true
              : false
          }
          label={"light"}
          subtext={"for when it was, infact, just a phase"}
        />
      </SettingsItem>
      <SettingsItem>
        <SettingTitle>desktop sidebar</SettingTitle>
        <TickboxToggle
          clickFunction={setSidebarSettingExpanded}
          themeSetting={themeSetting}
          active={
            sidebarSetting === "expanded"
              ? true
              : sidebarSetting === null
              ? true
              : false
          }
          label={"expanded"}
          subtext={"shows titles, icons and labels"}
        />

        <TickboxToggle
          clickFunction={setSidebarSettingCompact}
          themeSetting={themeSetting}
          active={sidebarSetting === "compact" ? true : false}
          label={"compact"}
          subtext={"shows icons only"}
        />
      </SettingsItem>
      <SettingsItem>
        <SettingTitle>playbar behaviour</SettingTitle>
        <TickboxToggle
          clickFunction={setPlaybarSettingsTrue}
          themeSetting={themeSetting}
          active={
            hidePlaybar === "true" ? true : hidePlaybar === null ? true : false
          }
          label={"always show"}
          subtext={
            "the playbar will always be visible, even when no show is selected"
          }
        />
        <TickboxToggle
          clickFunction={setPlaybarSettingsFalse}
          themeSetting={themeSetting}
          active={hidePlaybar === "true" ? false : true}
          label={"hide when nothing is playing"}
          subtext={
            "automatically hides the playbar when there's no show playing, like a good boy"
          }
        />
      </SettingsItem>
    </SettingsPageContainer>
  );
}

export default Apperance;
