import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./assets/fonts/fonts.css";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "easy-peasy";
import store from "./storeOld";

function render(Component) {
  ReactDOM.render(
    <StoreProvider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </StoreProvider>,
    document.getElementById("root")
  );
}
render(App);
if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./App", () => {
      render(App);
    });
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
