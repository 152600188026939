import React from "react";
import styled from "styled-components/macro";

import Header from "../core/header/loginHeader";
import LoginBox from "../core/login/loginBox";

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.primary};
`;

const ButtonContainer = styled.div`
  margin-top: 75px;
`;

const LoginBoxContainer = styled.div``;

function login(props) {
  return (
    <LoginContainer>
      <Header />
      <LoginBoxContainer>
        <LoginBox darkMode={props.darkMode} />
      </LoginBoxContainer>
    </LoginContainer>
  );
}

export default login;
