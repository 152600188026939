import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const SidebarItemContainerActive = styled(Link)`
  background-color: ${(props) => props.theme.inputBackgroundColorActive};
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  margin-top: 5px;
  text-decoration: none;
  margin-bottom: 5px;
  color: ${(props) => props.theme.primaryTextColor};
  @media (max-width: 1024px) {
    transition: 300ms;
  }
`;

const SidebarItemText = styled.div`
  padding-right: 40px;
`;

const SidebarItemContainer = styled(Link)`
  width: calc(100% - 40px);
  text-decoration: none;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  align-items: center;
  font-size: 18px;
  transition: 300ms;
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.subTextColor};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.primaryTextColor};
    background-color: ${(props) => props.theme.inputBackgroundColor};
  }
`;

function SidebarItem(props) {
  return props.active ? (
    <SidebarItemContainerActive
      to={props.link}
      onClick={() => props.onClickFunction(props.id)}
    >
      <SidebarItemText>{props.text}</SidebarItemText>
    </SidebarItemContainerActive>
  ) : (
    <SidebarItemContainer
      to={props.link}
      onClick={() => props.onClickFunction(props.id)}
    >
      {props.text}
    </SidebarItemContainer>
  );
}

export default SidebarItem;
