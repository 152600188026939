import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useParams, Link } from "react-router-dom";
import { shows } from "../shows/tempData";

import { ReactComponent as BreadcrumIcon } from "../../assets/images/icons/arrowForward.svg";

const ShowContainer = styled.div`
  margin-top: 50px;
  margin-left: 50px;
  @media (max-width: 1024px) {
    margin-left: 20px;
    margin-top: 55px;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

const NavigationItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.subTextColor};
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: ${(props) => props.theme.primaryTextColor};
  }
  @media (max-width: 1024px) {
    font-size: 11px;
  }
`;

const ActiveNavigationItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.primaryTextColor};
`;

const ShowTitle = styled.div`
  font-size: 50px;
  @media (max-width: 1024px) {
    font-size: 25px;
  }
`;

const ShowAuthor = styled.div`
  font-size: 30px;
  margin-top: 20px;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;

const ShowGenres = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ShowGenreItem = styled.div`
  background-color: ${(props) => props.theme.accentedPrimary};
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  margin-right: 5px;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const IconContainer = styled.div`
  height: 7.5px;
  width: 7.5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -8.5px;
  @media (max-width: 1024px) {
    height: 5px;
    width: 5px;
    margin-top: -17.5px;
  }
`;

function Show() {
  let urlId = useParams().id;
  const [showData, setshowData] = useState("");
  const [retrivedShowData, setRetrivedShowData] = useState(false);
  let item;

  function findArrayElementByTitle(array, title) {
    return array.find((element) => {
      return element.title === title;
    });
  }

  useEffect(() => {
    console.log(urlId);
    console.log(shows);
    item = shows.findIndex((x) => x.id === urlId);
    setshowData(shows[item]);
    setRetrivedShowData(true);
  });
  return retrivedShowData ? (
    <ShowContainer>
      <NavigationContainer>
        <NavigationItem to={"/shows"}>Shows</NavigationItem>
        <IconContainer>
          <BreadcrumIcon fill={"#fff"} />
        </IconContainer>
        <ActiveNavigationItem to={showData.id}>
          {showData.title}
        </ActiveNavigationItem>
      </NavigationContainer>
      <ShowTitle>{showData.title}</ShowTitle>
      <ShowAuthor>By {showData.author}</ShowAuthor>
      <ShowGenres>
        {showData.genres.map((item, index) => (
          <ShowGenreItem>{item}</ShowGenreItem>
        ))}
      </ShowGenres>
    </ShowContainer>
  ) : null;
}

export default Show;
