import React from "react";
import styled from "styled-components/macro";
import { useStoreState } from "easy-peasy";

const ActiveCompactSidebarItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const CompactSidebarItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const ActiveTextContainer = styled.div`
  color: ${(props) => props.theme.primaryTextColor};
  background-color: ${(props) => props.theme.opevoBrandAccent};
  transition: 300ms;
  height: 27px;
  width: 27px;
  border-radius: 10px;
  padding: 8px;
  :hover {
    transition: 300ms;
    color: ${(props) => props.hoverColor};
  }
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme.primaryTextColor};

  font-size: 22px;
  transition: 300ms;
  height: 27px;
  width: 27px;
  border-radius: 10px;
  padding: 8px;
  :hover {
    background-color: ${(props) => props.color};
    transition: 300ms;
    color: ${(props) => props.theme.primaryTextColor};
  }
`;

function CompactSidebarItem(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);
  return (
    <React.Fragment>
      {props.active ? (
        <ActiveCompactSidebarItemContainer
          backgroundColor={themeSetting === "dark" ? "#eee" : "#3e3d44"}
        >
          <ActiveTextContainer
            color={themeSetting === "dark" ? "#3a3856" : "#cecede"}
            hoverColor={themeSetting === "dark" ? "#aaaac9" : "#d3d3ea"}
          >
            <props.Icon fill={themeSetting === "dark" ? "#fff" : "#fff"} />
          </ActiveTextContainer>
        </ActiveCompactSidebarItemContainer>
      ) : (
        <CompactSidebarItemContainer onClick={() => props.updatePage(props.id)}>
          <TextContainer
            color={themeSetting === "dark" ? "#aaaac9" : "#7b7b98"}
            hoverColor={themeSetting === "dark" ? "#aaaac9" : "#d3d3ea"}
          >
            <props.Icon fill={themeSetting === "dark" ? "#fff" : "#000"} />
          </TextContainer>
        </CompactSidebarItemContainer>
      )}
    </React.Fragment>
  );
}

export default CompactSidebarItem;
