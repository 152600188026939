import React, { useState } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";

import MobileSearchItem from "./mobileSearchItem";

const MobileSearchBoxContainer = styled(motion.div)`
  max-width: 92.5%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  background-color: ${(props) => props.theme.accentedPrimary};
  margin-top: 10px;
  z-index: 20;
`;

const MobileSearchBoxInnerContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
`;

const Title = styled.div`
  color: ${(props) => props.theme.subTextColorClearer};
  width: 90%;
  margin-left: 5%;
  span {
    color: ${(props) => props.theme.primaryTextColor};
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  max-height: 40vh;
  overflow-y: auto;
`;

const searchBoxAnim = {
  visible: {
    height: "fit-content",
    transition: {
      delay: 0.1,
    },
  },
  hidden: {
    height: "0px",
  },
};

function MobileSearchBox(props) {
  function Items() {
    let timeout = true;
    setTimeout(() => {
      timeout = false;
    }, 1000);
    if (timeout) {
      return null;
    } else {
      return (
        <React.Fragment>
          <MobileSearchItem />
          <MobileSearchItem />
        </React.Fragment>
      );
    }
    return null;
  }
  if (props.searchQuery.length === 0) {
    return null;
  }
  return (
    <MobileSearchBoxContainer
      variants={searchBoxAnim}
      initial="hidden"
      animate={props.unloading ? "hidden" : "visible"}
    >
      <MobileSearchBoxInnerContainer>
        <Title>
          Search results for <span>{props.searchQuery}</span>
        </Title>
        <ItemsContainer>
          <MobileSearchItem openSearchResult={props.openSearchResult} />
          <MobileSearchItem openSearchResult={props.openSearchResult} />
        </ItemsContainer>
      </MobileSearchBoxInnerContainer>
    </MobileSearchBoxContainer>
  );
}

export default MobileSearchBox;
