import React from "react";
import styled from "styled-components/macro";

const MarketingIntroContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MarketingIntroInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  max-height: 1400px;
  background-color: ${(props) => props.theme.accentedPrimary};
`;

const Title = styled.div`
  font-size: 80px;
  font-family: prophet;
  font-weight: 800;
  text-align: center;
  user-select: none;
  color: ${(props) => props.primaryTextColor};
  span {
    color: #fff;
    background-color: #3a62f7;
    padding-left: 25px;
    padding-right: 25px;
    clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
    transition: 500ms;
    :hover {
      clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 6% 100%);
      transition: 500ms;
    }
  }

  @media (max-width: 700px) {
    font-size: 60px;
    line-height: 75px;
  }
  @media (max-width: 600px) {
    line-height: 85px;
  }
`;

function MarketingIntro() {
  return (
    <MarketingIntroContainer>
      <MarketingIntroInnerContainer>
        <Title>
          It's how you <span>hear</span> podcasts
        </Title>
      </MarketingIntroInnerContainer>
    </MarketingIntroContainer>
  );
}

export default MarketingIntro;
