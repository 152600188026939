import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { keyframes } from "styled-components/macro";

const animate = keyframes`
    0% {
        clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 6% 100%);
    }
`;

const AnimatedTitleOuterContainer = styled.div`
  padding: ${(props) => props.padding};
`;

const AnimatedTitleContainerAnimated = styled.div`
  text-decoration: none;
  font-family: circular;
  font-weight: 800;
  font-size: ${(props) => props.fontSize};
  user-select: none;
  color: ${(props) => props.color};
  height: fit-content;
  transition: 300ms;
  clip-path: polygon(3.5% 1%, 100% 0%, 97.5% 100%, 0% 100%);
  width: fit-content;
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  background-color: ${(props) => props.backgroundColor};
  :hover {
    animation: ${animate} 500ms alternate linear infinite;
    background-color: ${(props) => props.hoverBackgroundColor};
    transition: 300ms;
    color: #fff;
  }
`;

function AnimatedTitle(props) {
  return (
    <AnimatedTitleOuterContainer padding={props.padding || 0}>
      <AnimatedTitleContainerAnimated
        color={props.color || "#3a62f7"}
        fontSize={props.fontSize || "22px"}
        backgroundColor={props.backgroundColor || "transparent"}
        hoverBackgroundColor={props.hoverBackgroundColor || "#3a62f7"}
        paddingLeft={props.paddingLeft || "10px"}
        paddingRight={props.paddingRight || "10px"}
      >
        {props.text}
      </AnimatedTitleContainerAnimated>
    </AnimatedTitleOuterContainer>
  );
}

export default AnimatedTitle;
