import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Tick } from "../assets/images/tickboxTick.svg";

const TickboxContainer = styled.div`
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  cursor: pointer;
  background-color: transparent;
  border-color: ${(props) => props.hoverColor};
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  margin-right: 12px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    border-color: ${(props) => props.hoverColor};
  }
`;

const TickboxContainerActive = styled.div`
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  cursor: pointer;
  background-color: ${(props) => props.theme.inputBackgroundColor};
  border-color: ${(props) => props.theme.opevoBrandAccent};
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  transition: 300ms;
`;

const TickContainer = styled.div`
  height: 19px;
  width: 16px;
`;

function Tickbox(props) {
  return props.active ? (
    <TickboxContainerActive onClick={() => props.clickFunction}>
      <TickContainer>
        <Tick fill={props.themeSetting === "dark" ? "#fff" : "#3a62f7"} />
      </TickContainer>
    </TickboxContainerActive>
  ) : (
    <TickboxContainer
      hoverColor={
        props.hover
          ? props.themeSetting === "dark"
            ? "#b9c6f7"
            : "#3a62f7"
          : props.themeSetting === "dark"
          ? "#303238"
          : "#babbbe"
      }
    ></TickboxContainer>
  );
}

export default Tickbox;
