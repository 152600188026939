import React from "react";
import styled from "styled-components/macro";
import { useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";

import { ReactComponent as MoreIcon } from "../../assets/images/icons/expandMore.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/icons/playArrow.svg";

const FeedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 584px;
  width: calc(100% - 30px);
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.accentedPrimary};
  border-radius: 10px;
`;

const FeedActionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ProfilePicture = styled.div`
  background-color: #000;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7.5px;
`;

const Name = styled.div`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: 15px;
  line-height: 14px;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.opevoBrandAccent};
  }
  @media (max-width: 1024px) {
    line-height: 18px;
  }
`;

const Date = styled.div`
  color: ${(props) => props.theme.subTextColor};
  font-size: 10px;
  @media (max-width: 1024px) {
    margin-top: 2.5px;
  }
`;

const OptionsContainer = styled.div``;

const MoreIconContainer = styled.div`
  height: 22px;
  width: 22px;
  cursor: pointer;
`;

const FeedBody = styled.div`
  margin-left: 48px;
  margin-top: 5px;
  @media (max-width: 1024px) {
    margin-top: 10px;
  }
`;

const BodyText = styled.div`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const FeedObject = styled.div`
  height: 100px;
  width: 100%;
  background-color: ${(props) => props.theme.accentedPrimaryHover};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
`;

const ObjectDataContainer = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
  height: 100%;
`;

const ObjectArtwork = styled.div`
  height: 70px;
  width: 70px;
  border-radius: 10px;
  overflow: hidden;
`;

const ObjectArtworkImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ObjectMetadata = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ObjectTitle = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 2px;
`;

const ObjectArtist = styled.div`
  color: ${(props) => props.theme.subTextColor};
  font-size: 11px;
  margin-bottom: 2px;
`;

const ObjectLength = styled.div`
  color: ${(props) => props.theme.subTextColor};
  font-size: 11px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: 800;
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 2px;
  background-color: ${(props) => props.theme.subTextColor};
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
`;

const ObjectInteractionContainer = styled.div`
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const PlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-width: 2px;
  border-color: ${(props) => props.theme.primaryInverted};
  border-style: solid;
  border-radius: 50%;
  width: 17px;
  height: 17px;
`;

const PlayIconContainer = styled.div`
  height: 17px;
  width: 17px;
  margin-right: -5px;
`;

function FeedItem(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);

  return (
    <FeedItemContainer>
      {console.log(props)}
      <FeedActionInfo>
        <ProfileInfo>
          <ProfilePicture />
          <Metadata>
            <Name>
              {props.data.fullName} <span>{props.data.action}</span>{" "}
              <Link to="">{props.data.sharedObject}</Link> <span>by </span>
              <Link to="">{props.data.sharedObjectArtist}</Link>
            </Name>
            <Date>{props.data.date}</Date>
          </Metadata>
        </ProfileInfo>
        <OptionsContainer>
          <MoreIconContainer>
            <MoreIcon fill={themeSetting === "dark" ? "#000" : "#fff"} />
          </MoreIconContainer>
        </OptionsContainer>
      </FeedActionInfo>
      <FeedBody>
        <BodyText>{props.data.textBody}</BodyText>
        <FeedObject>
          <ObjectDataContainer>
            <ObjectArtwork>
              <ObjectArtworkImg src={props.data.objectArtwork} />
            </ObjectArtwork>
            <ObjectMetadata>
              <ObjectTitle>{props.data.sharedObject}</ObjectTitle>
              <ObjectArtist>{props.data.sharedObjectArtist}</ObjectArtist>
              <ObjectLength>
                {props.data.sharedObjectLength} <Divider />
                {props.data.sharedObjectReleaseDate}
              </ObjectLength>
            </ObjectMetadata>
          </ObjectDataContainer>
          <ObjectInteractionContainer>
            <PlayButton>
              <PlayIconContainer>
                <PlayIcon fill={themeSetting === "dark" ? "#fff" : "#000"} />
              </PlayIconContainer>
            </PlayButton>
          </ObjectInteractionContainer>
        </FeedObject>
      </FeedBody>
    </FeedItemContainer>
  );
}

export default FeedItem;
