import React from "react";
import styled from "styled-components/macro";
import { useStoreState } from "easy-peasy";

import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";
import officeArtwork from "../../assets/images/tmp/officeladies.jpg";

const MobileSearchItemContainer = styled.div`
  width: 90%;
  border-radius: 5px;
  background-color: ${(props) => props.theme.accentedPrimaryHover};
  min-height: 60px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 300ms;
  :active {
    transition: 300ms;
    background-color: ${(props) => props.theme.labelBackgroundColor};
    transform: scale(0.98);
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ArtworkContainer = styled.div`
  height: 40px;
  width: 40px;
  margin-left: 10px;
  border-radius: 5px;
  overflow: hidden;
`;

const Artwork = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const MetadataContainer = styled.div`
  margin-left: 10px;
`;

const Title = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.primaryTextColor};
  font-family: Circular;
`;

const Author = styled.div`
  font-size: 9px;
  font-family: Circular;
  color: ${(props) => props.theme.subTextColor};
`;

const IconContainer = styled.div`
  width: 15px;
  margin-right: 20px;
`;

function MobileSearchItem(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);

  return (
    <MobileSearchItemContainer
      onClick={() => props.openSearchResult("office_ladies")}
    >
      <LeftContent>
        <ArtworkContainer>
          <Artwork src={officeArtwork} />
        </ArtworkContainer>
        <MetadataContainer>
          <Title>Office Ladies</Title>
          <Author>
            <span>By</span> Jenna Fischer and Angela Kinsey
          </Author>
        </MetadataContainer>
      </LeftContent>
      <RightContent>
        <IconContainer>
          <InfoIcon fill={themeSetting === "dark" ? "#d2d2d2" : "#2b2b2b"} />
        </IconContainer>
      </RightContent>
    </MobileSearchItemContainer>
  );
}

export default MobileSearchItem;
