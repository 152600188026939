import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { keyframes } from "styled-components/macro";

const animate = keyframes`
    0% {
        clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 6% 100%);
    }
`;

const MenuItemOuterContainer = styled.div`
  padding: ${(props) => props.padding};
`;

const MenuItemContainerAnimated = styled(Link)`
  text-decoration: none;
  font-family: circular;
  font-weight: 800;
  font-size: ${(props) => props.fontSize};
  user-select: none;
  color: ${(props) => props.theme.ctaTextColor};
  height: fit-content;
  cursor: pointer;
  transition: 300ms;
  clip-path: polygon(3.5% 1%, 100% 0%, 97.5% 100%, 0% 100%);
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  background-color: ${(props) => props.backgroundColor};
  :hover {
    animation: ${animate} 500ms alternate linear infinite;
    background-color: ${(props) => props.hoverBackgroundColor};
    transition: 300ms;
    color: #fff;
  }
`;

const MenuItemContainer = styled(Link)`
  text-decoration: none;
  font-family: circular;
  font-weight: 800;
  font-size: ${(props) => props.fontSize};
  user-select: none;
  color: ${(props) => props.theme.ctaTextColor};
  height: fit-content;
  cursor: pointer;
  transition: 300ms;
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  clip-path: polygon(3.5% 1%, 100% 0%, 97.5% 100%, 0% 100%);
  background-color: ${(props) => props.backgroundColor};
`;

const MenuItem = styled.div``;

function menuItem(props) {
  return (
    <MenuItemOuterContainer padding={props.padding || 0}>
      {props.animated ? (
        <MenuItemContainerAnimated
          to={props.link}
          color={props.color || "#3a62f7"}
          fontSize={props.fontSize || "22px"}
          paddingLeft={props.paddingLeft || "10px"}
          paddingRight={props.paddingRight || "10px"}
          backgroundColor={props.backgroundColor || "transparent"}
          hoverBackgroundColor={props.hoverBackgroundColor || "#3a62f7"}
        >
          {props.text}
        </MenuItemContainerAnimated>
      ) : (
        <MenuItemContainer
          to={props.link}
          fontSize={props.fontSize || "22px"}
          paddingLeft={props.paddingLeft || "10px"}
          paddingRight={props.paddingRight || "10px"}
          backgroundColor={props.backgroundColor || "transparent"}
          color={props.color || "#3a62f7"}
        >
          {props.text}
        </MenuItemContainer>
      )}
    </MenuItemOuterContainer>
  );
}

export default menuItem;
