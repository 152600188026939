import React from "react";
import styled from "styled-components/macro";
import DesktopSidebar from "./desktopSidebar";
import MobileToolbar from "./mobileToolbar";
import { useStoreState } from "easy-peasy";
import MobileNavBar from "./mobileNavbar";

const SidebarControllerContainer = styled.div``;

const DesktopSidbarUI = styled.div``;

const DesktopSidebarContainer = styled.div`
  display: inline-block;
  height: 100%;
  position: fixed;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const SidebarSpacer = styled.div`
  width: ${(props) => props.width};
`;

const MobileSiderbarUI = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: inline-block;
  }
`;

function SidebarController() {
  const sidebarSetting = useStoreState((state) => state.settings.sidebar);
  return (
    <SidebarControllerContainer>
      <DesktopSidbarUI>
        <SidebarSpacer
          width={sidebarSetting === "expanded" ? "325px" : "95px"}
        />
        <DesktopSidebarContainer>
          <DesktopSidebar />
        </DesktopSidebarContainer>
      </DesktopSidbarUI>
      <MobileSiderbarUI>
        <MobileNavBar />
        <MobileToolbar />
      </MobileSiderbarUI>
    </SidebarControllerContainer>
  );
}

export default SidebarController;
