import React, { useEffect } from "react";

import LoggedOutHeader from "../core/header/loggedOutHeader.jsx";
import MarketingIntro from "../core/marketing/marketingIntro";
import ComingSoon from "../core/marketing/comingSoon.jsx";

function Home() {
  useEffect(() => {
    document.title = `Opevo`;
  });
  return (
    <React.Fragment>
      <LoggedOutHeader />
      <MarketingIntro />
      <ComingSoon />
    </React.Fragment>
  );
}

export default Home;
