import React from "react";
import styled from "styled-components/macro";

import Spacer from "../../utils/spacer";
import CtaItem from "../../components/ctaItem";

const LoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const InputItem = styled.input`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 300px;
  padding: 10px;
  border-width: 2px;
  border-color: transparent;
  font-size: 16px;
  outline: none;
  font-family: circular;
  background-color: ${(props) => props.theme.inputBackgroundColor};
  transition: 300ms;
  :focus {
    transition: 300ms;
    border-width: 2px;
    border-style: solid;
    border-color: #3a62f7;
  }
  :hover:not(:focus) {
    transition: 300ms;
    border-width: 2px;
    border-style: solid;
    border-color: #b9c6f7;
  }
  @media (max-width: 768px) {
    width: 65%;
  }
`;

function LoginForm() {
  return (
    <LoginFormContainer>
      <InputItem placeholder="Email" autofocus="true" />
      <InputItem placeholder="Password" type="password" />
      <Spacer height={"5px"} />
      <CtaItem
        link={"/feed"}
        text={"login"}
        color={"#3a62f7"}
        hoverBackgroundColor={"#3a62f7"}
        fontSize={"30px"}
        animated
      />
    </LoginFormContainer>
  );
}

export default LoginForm;
