import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components/macro";
import { useStoreState, useStoreActions } from "easy-peasy";
import SettingsSidebar from "./settingsSidebar";
import Apperance from "./apperance";
import PageComingSoon from "./pageComingSoon";

import { ReactComponent as CloseIcon } from "../../assets/images/closeIcon.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/menuIcon.svg";

const openAnim = keyframes` 
    0% {transform: scale(1.2);}
    100% {transform: scale(1);}
`;

const closeAnim = keyframes` 
    0% {transform: scale(1);}
    100% {transform: scale(1.2); opacity: 0;}
`;

const SettingsContainerOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  overflow: hidden;
  width: 100%;
  height: 100%;
  animation-name: ${(props) => props.anim};
  animation-duration: 200ms;
  animation-iteration-count: 1;
`;

const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.primary};
  display: flex;
  animation-duration: 0s;
`;

const SettingsPage = styled.div`
  width: calc(100% - 300px);
  max-width: 660px;
  margin-left: 60px;
  margin-top: 40px;
  margin-right: 60px;
  margin-bottom: 80px;
  background-color: blue;
  height: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.primary};

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 0px;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 70px;
  }
`;

const CloseSettingsContainer = styled.div`
  padding-top: 60px;
`;

const OpenMobileSettingsSidebarContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    padding-top: 60px;
  }
`;

const IconContainer = styled.div`
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 22.5px;
  margin-right: 20px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: ${(props) => props.theme.subTextColor};
  line-height: 9.5px;
  @media (max-width: 1024px) {
    font-size: 0px;
    line-height: 0px;
  }
`;

const MenuIconContainer = styled.div`
  width: 30px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 22.5px;
  margin-left: 20px;
  cursor: pointer;
`;

const Temp = styled.div``;

function Settings(props) {
  const themeSetting = useStoreState((store) => store.settings.theme);
  const toggleSettingsPanel = useStoreActions(
    (actions) => actions.overlays.updateSettingsOverlay
  );
  const [page, setpage] = useState("apperance");
  const [closingPanel, setClosingPanel] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  function handlePageChange(id) {
    setpage(id);
    toggleMobileMenu();
  }
  function ActivePage(props) {
    switch (page) {
      case "apperance":
        return <Apperance />;
        break;
      case "playback":
        return <PageComingSoon title={"playback"} />;
        break;
      case "social":
        return <PageComingSoon title={"social"} />;
        break;
      case "other":
        return <PageComingSoon title={"other"} />;
        break;
      case "account":
        return <PageComingSoon title={"account"} />;
        break;
      case "privacy":
        return <PageComingSoon title={"privacy"} />;
        break;
      case "logout":
        return <PageComingSoon title={"logout"} />;
        break;
      default:
        return <div>Error: Page not found.</div>;
    }
  }

  function handleClose(props) {
    setClosingPanel(true);
    setTimeout(() => {
      toggleSettingsPanel(false);
    }, 200);
  }

  function toggleMobileMenu(props) {
    if (mobileMenuActive) {
      setMobileMenuActive(false);
    } else {
      setMobileMenuActive(true);
    }
  }

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "Escape") {
          handleClose();
        }
      },
      false
    );
    return () => {
      document.removeEventListener("keydown", () => handleClose(), false);
    };
  });

  return (
    <SettingsContainerOuter anim={closingPanel ? closeAnim : openAnim}>
      <SettingsContainer>
        <SettingsSidebar
          handlePageChange={handlePageChange}
          page={page}
          mobileMenuActive={mobileMenuActive}
          toggleMobileMenu={toggleMobileMenu}
        />
        <SettingsPage>
          <ActivePage />
        </SettingsPage>
        <CloseSettingsContainer>
          <IconContainer>
            <CloseIcon
              onClick={() => handleClose(props.closeSettings)}
              fill={themeSetting === "dark" ? "#fff" : "#000"}
            />
            esc
          </IconContainer>
        </CloseSettingsContainer>
        <OpenMobileSettingsSidebarContainer>
          <MenuIconContainer>
            <MenuIcon
              stroke={themeSetting === "dark" ? "#fff" : "#000"}
              onClick={() => toggleMobileMenu()}
            />
          </MenuIconContainer>
        </OpenMobileSettingsSidebarContainer>
      </SettingsContainer>
    </SettingsContainerOuter>
  );
}

export default Settings;
