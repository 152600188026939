import React from "react";
import styled from "styled-components/macro";
import { useStoreState } from "easy-peasy";

const MobileToolbarItemContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 23px;
`;

function MobileToolbarItem(props, Icon) {
  const themeSetting = useStoreState((store) => store.settings.theme);

  if (props.active) {
    return (
      <MobileToolbarItemContainer onClick={() => props.updatePage(props.id)}>
        <IconContainer>
          <props.Icon fill={themeSetting === "dark" ? "#3a62f7" : "#3a62f7"} />
        </IconContainer>
      </MobileToolbarItemContainer>
    );
  } else {
    return (
      <MobileToolbarItemContainer onClick={() => props.updatePage(props.id)}>
        <IconContainer>
          <props.Icon fill={themeSetting === "dark" ? "#fff" : "#000"} />
        </IconContainer>
      </MobileToolbarItemContainer>
    );
  }
}

export default MobileToolbarItem;
