import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import MenuItem from "../../components/menuItem";

const LoggedOutHeaderContainer = styled.div`
  width: 100%;
  height: 75px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
`;

const BrandContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

const KeyLetter = styled.div`
  font-family: prophet;
  font-weight: 800;
  margin-left: 20px;
  color: #8a23e8;
  line-height: 0px;
  font-size: 65px;
`;

const BrandName = styled(Link)`
  font-family: prophet;
  font-weight: 800;
  font-size: 50px;
  margin-left: 0px;
  line-height: 0px;
  user-select: none;
  transition: 300ms;
  color: #000;
  text-decoration: none;
  color: ${(props) => props.theme.primaryTextColor};
  span {
    color: #3a62f7;
  }
  :hover {
    transition: 300ms;
    color: #3a62f7;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const ContentLeftContainer = styled.div`
  height: 100%;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  align-items: center;
`;

function LoggedOutHeader() {
  return (
    <LoggedOutHeaderContainer>
      <ContentLeftContainer>
        <BrandName to={"/"}>
          <span>O</span>pevo.
        </BrandName>
      </ContentLeftContainer>
      <ContentRightContainer>
        <MenuItem text={"login"} link={"/login"} />
        <MenuItem text={"register"} link={"/register"} />
      </ContentRightContainer>
    </LoggedOutHeaderContainer>
  );
}

export default LoggedOutHeader;
