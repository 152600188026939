import React from "react";
import styled from "styled-components/macro";

import Spacer from "../../utils/spacer";
import CtaItem from "../../components/ctaItem";

const RegisterFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const InputItem = styled.input`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 300px;
  padding: 10px;
  border-width: 2px;
  border-color: transparent;
  font-size: 16px;
  outline: none;
  font-family: circular;
  background-color: ${(props) => props.theme.inputBackgroundColor};
  transition: 300ms;
  color: ${(props) => props.theme.subTextColor};
  ::placeholder {
    color: ${(props) => props.theme.subTextColor};
  }
  :focus {
    transition: 300ms;
    border-width: 2px;
    border-style: solid;
    border-color: #3a62f7;
    background-color: ${(props) => props.theme.inputBackgroundColorActive};
  }
  :hover:not(:focus) {
    transition: 300ms;
    border-width: 2px;
    border-style: solid;
    border-color: #b9c6f7;
  }
  @media (max-width: 768px) {
    width: 65%;
  }
`;

const InputSelect = styled.select`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 325px;
  height: 44px;
  border-width: 2px;
  border-color: transparent;
  font-size: 16px;
  outline: none;
  font-family: circular;
  background-color: #fffcfc;
  transition: 300ms;
  background-color: ${(props) => props.theme.inputBackgroundColor};
  color: ${(props) => props.theme.subTextColor};
  :focus {
    transition: 300ms;
    border-width: 2px;
    border-style: solid;
    border-color: #3a62f7;
    background-color: ${(props) => props.theme.inputBackgroundColorActive};
  }
  :hover:not(:focus) {
    transition: 300ms;
    border-width: 2px;
    border-style: solid;
    border-color: #b9c6f7;
  }
  @media (max-width: 768px) {
    width: calc(65% + 25px);
  }
`;

function RegisterForm() {
  return (
    <RegisterFormContainer>
      <InputItem placeholder="Email" autofocus="true" />
      <InputItem placeholder="Email (Again)" autofocus="true" />
      <InputItem placeholder="Password" type="password" />

      <InputSelect>
        <option selected disabled>
          Select
        </option>
        <option value="january">January</option>
        <option value="february">February</option>
        <option value="march">March</option>
        <option value="april">April</option>
        <option value="may">May</option>
        <option value="june">June</option>
        <option value="july">July</option>
        <option value="august">August</option>
        <option value="september">September</option>
        <option value="october">October</option>
        <option value="november">November</option>
        <option value="december">December</option>
      </InputSelect>
      <InputItem placeholder="Day" type="number" />
      <InputItem placeholder="Year" type="number" />

      <Spacer height={"5px"} />
      <CtaItem
        link={"#"}
        text={"register"}
        color={"#3a62f7"}
        hoverBackgroundColor={"#3a62f7"}
        fontSize={"30px"}
        animated
      />
    </RegisterFormContainer>
  );
}

export default RegisterForm;
