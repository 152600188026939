import React from "react";
import styled from "styled-components/macro";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import CompactSidebarItem from "./compactSidebarItem";
import SidebarItem from "./sidebarItem";

import { ReactComponent as DashboardIcon } from "../../assets/images/icons/dashboard.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";
import { ReactComponent as InboxIcon } from "../../assets/images/icons/messages.svg";
import { ReactComponent as TrendingIcon } from "../../assets/images/icons/trendingUp.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/icons/playCircle.svg";
import { ReactComponent as FollowingIcon } from "../../assets/images/icons/account.svg";
import { ReactComponent as SubscriptionsIcon } from "../../assets/images/icons/subscriptions.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/images/icons/feedback.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/settings.svg";
import { ReactComponent as AlertIcon } from "../../assets/images/icons/alert.svg";

const SidebarContainerOuter = styled.div`
  height: 100vh;
  min-width: ${(props) => props.minWidth};
`;

const CompactSidebarContainer = styled.div`
  height: 100%;
  width: 95px;
  background-color: ${(props) => props.theme.accentedPrimary};
  height: 100%;
  min-height: 100vh;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CompactSidebarSpacer = styled.div`
  width: 95px;
  height: 100%;
  z-index: 0;
`;

const SidebarItems = styled.div`
  margin-left: 15px;
`;

const CompactSidebarItems = styled.div`
  margin-left: 0px;
`;

const SidebarContainer = styled.div`
  height: 100%;
  width: 325px;
  background-color: ${(props) => props.theme.accentedPrimary};
  min-height: 100vh;
  height: fit-content;
  position: relative;
  min-height: 100vh;
  height: 100%;
`;

const BrandingContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 50px;
  font-family: prophet;
  font-weight: 800;
  padding-top: 40px;
  padding-bottom: 20px;
  color: ${(props) => props.theme.primaryTextColor};
  span {
    font-size: 50px;
    color: #3a62f7;
  }
  text-decoration: none;
  user-select: none;
`;

const BrandingContainerCompact = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 50px;
  font-family: prophet;
  font-weight: 800;
  padding-top: 20px;
  padding-bottom: 0px;
  color: ${(props) => props.theme.primaryTextColor};
  text-decoration: none;
  user-select: none;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #3a62f7;
  }
`;

const UserAreaCompact = styled.div`
  bottom: 0;
  left: 0;
  width: 50%;
  margin-left: 25%;
  margin-bottom: 30px;
  border: 0px;
  border-top-width: 1px;
  border-color: #74707d;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserArea = styled.div`
  width: 70%;
  margin-left: 15%;
  margin-bottom: 30px;
  border: 0px;
  border-top-width: 1px;
  border-color: #74707d;
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UserIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${(props) => props.theme.primaryInverted};
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: circular;
  color: ${(props) => props.theme.accentedPrimary};
  font-size: 22px;
  cursor: pointer;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #fff;
    background-color: ${(props) => props.theme.opevoBrandAccent};
  }
`;

const UserProfile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  transition: 300ms;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  width: fit-content;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

const UserMetadata = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div``;

const UserEmail = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.subTextColor};
`;

const BottomArea = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  padding-bottom: 25px;
  margin-top: 40px;
`;

const BottomAreaCompact = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  margin-top: 20px;
`;

const BottomItemsContainer = styled.div`
  margin-left: 15px;
`;

const CompactBottomItemsContainer = styled.div`
  margin-left: 0px;
`;

const SidebarInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 100px);
  overflow-y: auto;
`;

const UserProfileCompact = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  transition: 300ms;

  margin-bottom: 20px;
`;

const UserIconCompact = styled.div`
  width: 35px;
  height: 35px;
  color: ${(props) => props.theme.accentedPrimary};
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: circular;
  background-color: ${(props) => props.theme.primaryInverted};
  font-size: 22px;
  cursor: pointer;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #fff;
    background-color: ${(props) => props.theme.opevoBrandAccent};
  }
`;

function Sidebar(props) {
  const widthState = useStoreState((state) => state.settings.sidebar);
  const toggleSettingsPanel = useStoreActions(
    (actions) => actions.overlays.updateSettingsOverlay
  );
  let history = useHistory();
  let location = useLocation();

  function handlePageUpdate(id) {
    if (id === "settings") {
      toggleSettingsPanel(true);
    } else {
      history.push("/" + id);
    }
  }

  return (
    <SidebarContainerOuter
      minWidth={widthState === "compact" ? "95px" : "325px"}
    >
      {widthState === "compact" ? (
        <CompactSidebarContainer>
          <BrandingContainerCompact onClick={() => handlePageUpdate("feed")}>
            O
          </BrandingContainerCompact>

          <SidebarInnerContainer>
            <CompactSidebarItems>
              <CompactSidebarItem
                text={"feed"}
                id={"feed"}
                Icon={DashboardIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/feed" ? true : false}
              />
              <CompactSidebarItem
                text={"player"}
                id={"player"}
                Icon={PlayIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/player" ? true : false}
              />
              <CompactSidebarItem
                text={"search"}
                id={"search"}
                Icon={SearchIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/search" ? true : false}
              />
              <CompactSidebarItem
                text={"trending"}
                id={"trending"}
                Icon={TrendingIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/trending" ? true : false}
              />
              <CompactSidebarItem
                text={"following"}
                id={"following"}
                Icon={FollowingIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/following" ? true : false}
              />

              <CompactSidebarItem
                text={"inbox"}
                id={"inbox"}
                Icon={InboxIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/inbox" ? true : false}
              />
              <CompactSidebarItem
                text={"subscriptions"}
                id={"subscriptions"}
                Icon={SubscriptionsIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/subscriptions" ? true : false}
              />
              <CompactSidebarItem
                text={"settings"}
                id={"settings"}
                Icon={SettingsIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/settings" ? true : false}
              />
            </CompactSidebarItems>
            <BottomAreaCompact>
              <UserArea />
              <CompactBottomItemsContainer>
                <CompactSidebarItem
                  text={"feedback"}
                  margin={"0px 0px 0px 0px"}
                  id={"feedback"}
                  Icon={FeedbackIcon}
                  updatePage={handlePageUpdate}
                  active={location.pathname === "/feedback" ? true : false}
                />
                <UserProfileCompact>
                  <UserIconCompact>J</UserIconCompact>
                </UserProfileCompact>
              </CompactBottomItemsContainer>
            </BottomAreaCompact>
          </SidebarInnerContainer>
        </CompactSidebarContainer>
      ) : (
        <SidebarContainer>
          <BrandingContainer onClick={() => handlePageUpdate("dashboard")}>
            <span>O</span>pevo.
          </BrandingContainer>

          <SidebarInnerContainer>
            <SidebarItems>
              <SidebarItem
                text={"feed"}
                id={"feed"}
                Icon={DashboardIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/feed" ? true : false}
              />
              <SidebarItem
                text={"player"}
                id={"player"}
                Icon={PlayIcon}
                label={"Beta"}
                LabelIcon={AlertIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/player" ? true : false}
              />
              <SidebarItem
                text={"search"}
                id={"search"}
                Icon={SearchIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/search" ? true : false}
              />
              <SidebarItem
                text={"trending"}
                id={"trending"}
                Icon={TrendingIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/trending" ? true : false}
              />
              <SidebarItem
                text={"following"}
                id={"following"}
                Icon={FollowingIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/following" ? true : false}
              />
              <SidebarItem
                text={"inbox"}
                id={"inbox"}
                Icon={InboxIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/inbox" ? true : false}
              />
              <SidebarItem
                text={"subscriptions"}
                id={"subscriptions"}
                Icon={SubscriptionsIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/subscriptions" ? true : false}
              />
              <SidebarItem
                text={"settings"}
                id={"settings"}
                Icon={SettingsIcon}
                updatePage={handlePageUpdate}
                active={location.pathname === "/settings" ? true : false}
              />
            </SidebarItems>

            <BottomArea>
              <UserArea />
              <BottomItemsContainer>
                <SidebarItem
                  text={"feedback"}
                  margin={"0px 0px -15px 0px"}
                  id={"feedback"}
                  Icon={FeedbackIcon}
                  updatePage={handlePageUpdate}
                  active={location.pathname === "/feedback" ? true : false}
                />
                <UserProfile>
                  <UserIcon>J</UserIcon>
                  <UserMetadata>
                    <UserName>Josh Cawthorne</UserName>
                    <UserEmail>joshcawthorne97@gmail.com</UserEmail>
                  </UserMetadata>
                </UserProfile>
              </BottomItemsContainer>
            </BottomArea>
          </SidebarInnerContainer>
        </SidebarContainer>
      )}
    </SidebarContainerOuter>
  );
}

export default Sidebar;
