import React from "react";
import styled from "styled-components/macro";

import Header from "../core/header/loggedOutHeader";
import RegistrationBox from "../core/registration/registerBox";

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
const LoginBoxContainer = styled.div``;

function Register(props) {
  return (
    <RegisterContainer>
      <Header />
      <LoginBoxContainer>
        <RegistrationBox darkMode={props.darkMode} />
      </LoginBoxContainer>
    </RegisterContainer>
  );
}

export default Register;
