import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useStoreState } from "easy-peasy";
import { motion } from "framer-motion";
import useOnclickOutside from "react-cool-onclickoutside";

import MobileSearchBox from "../search/mobileSearchBox";

import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";

const MobileNavBarContainer = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const MobileNavBarInnerContainer = styled.div`
  height: 50px;
  width: 92.5%;
  border-radius: 5px;
  background-color: ${(props) => props.theme.accentedPrimary};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchbarContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
`;

const SearchIconContainer = styled.div`
  width: 13px;
`;

const Searchbar = styled.input`
  background-color: transparent;
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 10px;
  border: none;
  font-family: Circular;
  font-size: 12px;
  color: ${(props) => props.theme.primaryTextColor};
`;

const Divider = styled.div`
  border-style: solid;
  border-width: 0px;
  border-left-width: 1px;
  border-color: #ccc;
  width: 1px;
  height: 65%;
`;

const UserIconContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserIcon = styled.div`
  width: 30px;
  height: 30px;
  color: ${(props) => props.theme.accentedPrimary};
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: circular;
  background-color: ${(props) => props.theme.primaryInverted};
  font-size: 15px;
  cursor: pointer;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #fff;
    background-color: ${(props) => props.theme.opevoBrandAccent};
  }
`;

function MobileNavBar() {
  const themeSetting = useStoreState((store) => store.settings.theme);
  const [searchActive, setsearchActive] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [unloading, setUnloading] = useState(false);
  const ref = useRef();

  let history = useHistory();

  useOnclickOutside(ref, () => {
    unloadSearch();
  });

  function openSearchResult(result) {
    history.push("/show/" + result);
    unloadSearch();
  }

  function unloadSearch() {
    setUnloading(true);
    setTimeout(() => {
      handleSearchFocusLost();
      setUnloading(false);
    }, 500);
  }

  function handleSearchFocus() {
    setsearchActive(true);
  }

  function handleSearchFocusLost() {
    console.log("lost");
    setsearchActive(false);
  }

  function handleSearchInput(value) {
    setsearchValue(value);
  }

  return (
    <MobileNavBarContainer ref={ref}>
      <MobileNavBarInnerContainer>
        <SearchbarContainer>
          <SearchIconContainer>
            <SearchIcon fill={themeSetting === "dark" ? "#4F535B" : "#000"} />
          </SearchIconContainer>
          <Searchbar
            id={"mobileSearch"}
            value={searchValue}
            placeholder={"Search..."}
            onChange={(e) => handleSearchInput(e.target.value)}
            onFocus={() => handleSearchFocus()}
          />
        </SearchbarContainer>
        <Divider />
        <UserIconContainer>
          <UserIcon>J</UserIcon>
        </UserIconContainer>
      </MobileNavBarInnerContainer>
      {searchActive ? (
        <MobileSearchBox
          searchQuery={searchValue}
          unloading={unloading}
          openSearchResult={openSearchResult}
        />
      ) : null}
    </MobileNavBarContainer>
  );
}

export default MobileNavBar;
