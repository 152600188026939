import React, { useState } from "react";
import styled from "styled-components/macro";
import Tickbox from "../../components/tickbox";

const SettingContainer = styled.div`
  background-color: ${(props) => props.theme.accentedPrimary};
  width: calc(100% - 20px);
  min-height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px;
  cursor: pointer;
  transition: 300ms;
  border-radius: 5px;
  :hover {
    transition: 300ms;
    background-color: ${(props) => props.theme.accentedPrimaryHover};
  }
`;

const SettingsLabel = styled.label`
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SettingSubtext = styled.div`
  font-size: 12px;
  font-family: circular;
  font-weight: 500;
  color: ${(props) => props.theme.subTextColor};
  cursor: pointer;
  @media(max-width: 1024px) {
    font-size: 10px;
  }
    
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function TickboxToggle(props) {
  const [hover, sethover] = useState(false);
  return (
    <SettingContainer
      onClick={() => props.clickFunction()}
      onMouseEnter={() => sethover(true)}
      onMouseLeave={() => sethover(false)}
    >
      <SettingsLabel>
        <Tickbox
          clickFunction={props.clickFunction}
          active={props.active ? true : false}
          themeSetting={props.themeSetting}
          hover={hover}
        />
        <LabelContainer>
          {props.label}
          <SettingSubtext>{props.subtext}</SettingSubtext>
        </LabelContainer>
      </SettingsLabel>
    </SettingContainer>
  );
}

export default TickboxToggle;
